import React from 'react'
import { graphql } from 'gatsby'

import SEO from '@components/SEO'
import Layout from '@components/Layout'
import PostsOverview from '@components/PostsOverview'

const BlogPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const posts = edges
    .filter((edge) => !!edge.node.frontmatter.date)
    .map((edge) => {
      const post = {
        description: edge.node.excerpt,
        title: edge.node.frontmatter.title,
        path: edge.node.frontmatter.path,
        date: edge.node.frontmatter.date,
        img: edge.node.frontmatter.Thumbnail,
      }

      return post
    })

  const data = {
    title: 'Blog',
    posts,
  }

  return (
    <Layout>
      <SEO title="Blog" />
      <PostsOverview data={data} />
    </Layout>
  )
}

export default BlogPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            path
            Thumbnail
          }
        }
      }
    }
  }
`
